<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Tablero general</h5>
        <div class="p-fluid formgrid grid">
          <div class="field col-4">
            <div class="card">
              <h1>En ruta</h1>
              <h2 style="font-size: 5em">{{counters.inRoute}}</h2>
              <p><i>Actualizado en tiempo real</i></p>
            </div>
          </div>
          <div class="field col-4">
            <div class="card">
              <h1>Por recoger</h1>
              <h2 style="font-size: 5em">{{counters.pickupPending}}</h2>
              <p><i>Actualizado en tiempo real</i></p>
            </div>
          </div>
          <div class="field col-4">
            <div class="card">
              <h1>Por entregar</h1>
              <h2 style="font-size: 5em">{{counters.deliveryPending}}</h2>
              <p><i>Actualizado en tiempo real</i></p>
            </div>
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="field col-12">
            <div class="card">
              <h2>Órdenes activas</h2>
              <DataTable :value="orders" responsiveLayout="scroll" :sortOrder="1" sortField="selectedDate">
                <Column header="TRK ID">
                  <template #body="slotProps">
                    <div @click="trackByID(slotProps.data.trkID)">
                      {{slotProps.data.trkID}}
                    </div>
                  </template>
                </Column>
                <Column field="displayName" header="Cliente"></Column>
                <Column field="date" header="Creada el" :sortable="true"></Column>
                <Column field="state" header="Estatus"></Column>
                <Column field="selectedDate" header="Entrega/Recolección" :sortable="true"></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, pickup } from "../logic/firebase";
let orders = []
let counters = {
  inRoute: 0,
  pickupPending: 0,
  deliveryPending: 0
}

const validStates = [
    'Recolección pendiente',
    'Recolección en proceso',
    'Orden recolectada',
    'Recibida en sucursal',
    'Asignar entrega',
    'Lista para recoger',
    'Entrega fallida',
    'Entrega pendiente',
    'Entrega en proceso',
]

export default {
  data() {
    return {
      orders: orders,
      counters: counters,
      validStates: validStates
    }
  },


  methods: {
    listenOrders(){
      pickup.where('state', 'in', this.validStates).orderBy('date', 'asc').onSnapshot((querySnapshot) => {
        this.resetList();
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.orders.push(data);
          switch (data.state){
            case 'Entrega en proceso':
            case 'Recolección en proceso':
              this.counters.inRoute++;
              break;
            case 'Recolección pendiente':
              this.counters.pickupPending++;
              break;
            case 'Entrega pendiente':
              this.counters.deliveryPending++;
              break;
            default:
              break;
          }
        })
      })
    },//listenOrderList

    resetList(){
      this.orders = []
      this.counters = {
        inRoute: 0,
        pickupPending: 0,
        deliveryPending: 0
      }
    },//resetList

    trackByID(id){
      this.$router.push({
        path: 'OrderTracking',
        query:{
          trkID: id
        }
      });
    },//trackByID

  },


  mounted() {
    this.listenOrders()
  },
}
</script>

<style scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}
</style>